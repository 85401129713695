// Utilities
@import "../../shared";

// WIDESCREEN
.navbar {
  .header{
    height: 80px;
    padding: 0;
    .logo{
      position: relative;
      height: 80px;
    }
  }
  nav {
    width: 100%;
    top: 0;
    padding: 0;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: $color-dark-blue;
  }
  li {
    float: right;
    margin-top: 15px;
    a {
      display: inline-block;
      color: $color-gray-background;
      text-align: center;
      padding: 18px;
      font-size: 0.8rem;
      font-weight: 600;
      text-decoration: none; /* no underline */
      letter-spacing: 1px;

      &:hover {
        background-color: $color-blue;
      }
    }
  }
}

div.header {
    padding: 30px;
    a {
      text-decoration: none; /* no underline */
      color: $color-gray-background;
      font-weight: 500;
    }
}

// MOBILE
.navbar-mobile {
  position: relative;
  height: 90%;
  width: 100%;
  right: 0px;
  top: 0px;
  background: $color-dark-blue;
  padding: 0;
  margin: 0;
  text-align: center;
  .logo{
    position: relative;
    height: 80px;
  }
  ul{
    margin: 0;
    margin-top: 20px;
    padding: 0;
  }

  li {
    list-style-type: none;
    a {
      display: block;
      color: $color-gray-background;
      text-align: center;
      margin: 30px;
      font-weight: 400;
      text-decoration: none; /* no underline */
      letter-spacing: 1px;
      font-size: 0.8rem;
    }
  }

  .menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      height: 0;
      opacity: 0;
    }
  }

  div.header {
      padding: 12px 30px;
      padding-right: 8px;
      a {
        text-decoration: none; /* no underline */
        color: $color-gray-background;
      }
    }

    .arrow-down{
      padding: 8px 20px;
      transform: rotateX(180deg);
    }

    .arrow-up{
      padding: 8px 20px;
      transform: rotateX(0deg);
    }

    button{
      background-color: transparent;
      border: none;
      text-align: center;
      outline: none;

      &:hover {
        cursor: pointer
      }
    }
}
