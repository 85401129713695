@import "../../../shared";

// WIDESCREEN STYLES
.row-section {
  text-align: center;
  margin: 0 auto;
  min-width: 60%;
  padding-top: 100px;

  .card {
    padding: 0;
  }
  .row-text {
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .row-section-image-div {
    width: 60%;
    max-width: 1100px;
    overflow: hidden;
    margin: auto;
  }
}

.line {
  width: 60%;
  border-top: 1px solid $color-brown;
  margin-top: 20px;
}

// MOBILE STYLES
.row-section-mobile {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding-top: 50px;


  .card {
    margin: 0 auto;
    width: 90%;
    padding-top: 10px;
    padding-bottom: 10px;
    p{
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
}
