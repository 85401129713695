@import url("https://fonts.googleapis.com/css?family=Abril+Fatface");
@import "../../shared";

.page-interpreters {
  .header-top {
    text-align: center;
    font-weight: 100;
    font-size: 3rem;
    padding:100px 0;
    margin:0;
  }
  .header {
    text-align: center;
    padding: 20px 0;
    margin: 0;
  }
  // .header-rules {
  //   font-family: "Abril Fatface", serif;
  // }
  ol {
    list-style: none;
    counter-reset: counter;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 30px 20px;
  }
  ol li {
    counter-increment: counter;
    display: flex;
    width: calc(50% - 8px);
    font-size: 0.9rem;
    margin-bottom: 1rem;
    padding-right: 8px;
    align-items: center;
  }
  ol li::before {
    content: counter(counter);
    font-weight: bold;
    font-size: 1.8rem;
    margin-right: 0.5rem;
    line-height: 1;
    width: 34px;
    flex-shrink: 0;
    text-align: center;
  }
  .people {
    text-align: center;
    padding-bottom: 20px;
  }
  .background-gray {
    background-color: $color-gray-background;
  }
  .paragraph {
    text-align: center;
    padding-bottom: 20px;
    p {
      font-size: 1.2rem;
    }
  }
  .container {
    padding: 50px 0;
  }
  a {
    color: $color-light-brown;
    text-decoration: none;
  }
}

@media screen and (max-width: 800px) {
  .page-interpreters {
    ol {
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      li {
        width: 100%;
      }
    }
  }
}
