@import "../../shared";

.page-contact-info {
	position: relative;
	display: block;
	text-align: center;
	overflow: hidden;
    padding-bottom: 50px;
    padding-top: 100px;
    width: 100%;
    .header{
        padding: 20px 0;
    }
    .person-info {
        padding-bottom: 8px
    }
    .container{
        padding: 40px 0;
    }
    .background-gray {
        background-color: $color-gray-background;
      }
    .employer-info{
        width:100%;
    }
	h1 {
    	font-weight: 100;
        font-size: 3rem;
        padding-top:0;
        margin:0;
    }
    h5 {
        font-size: 1.3rem;
        margin-bottom: 6px;
    }
	p{
        // letter-spacing: 0.12rem;
        margin-bottom: 6px;
        margin-top: 6px;
    }
    a {
        color: black;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}
