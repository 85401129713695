@import "../../../shared";

.contact {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 100px;
    .textDiv{
      padding-top: 100px;
      padding-bottom: 100px;
      p{
        font-size: 1.5rem;
        padding-left:20px;
      }
      h1{
        font-size: 2rem;
        transform: scale(1, 1.1);
      }
      .icon{
        position:relative;
        top: 3px;
        right: 15px;
        height: 23px;
      }
    }
    a{
      text-decoration: none;
      color: black;
      word-break: break-all;
    }
}

