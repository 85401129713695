@import "../../../shared";

.products {
  padding: 0px;
  .container{
    width: 100%;
    .product-card {
      width: 70%;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 50px;
      max-width: 1100px;

      .text {
        position: relative;
        float: left;
        padding-bottom: 50px;
        min-width: 80%;
      }
      .image {
        width: 20%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        img {
          height: 350px;
          max-width: 350px;
        }
      }
    }
  }
}

.products-mobile {
  padding: 0px;
  .product-card {
    width: 100%;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    .text {
      position: relative;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 50px;
      width: 90%;
      h3{
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }
    .image {
      position: relative;
      text-align: center;
      height: 100%;
      img{
        vertical-align: middle;
        width: 300px;
      }
    }
  }
}

@media only screen and (min-width: 1201px) {
  .products-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .products {
    display: none;
  }
}
