@import "../../shared";

footer {
  width: 100%;
  text-align: center;
  background-color: $color-dark-blue;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-bottom: 30px;
  h2 {
    color: $color-light-blue;
    font-size: 1.3rem;
  }
  h4 {
    line-height: 2px;
    color: white;
    font-size: 1.2rem;
  }
  p {
    color: white;
    font-size:0.9rem;
    line-height: 20px;
    font-weight: 400;
    margin: 4px;
  }
  a {
    text-decoration: none;
    color: $color-gray-background
  }
  .logo{
    height: 100px;
  }
  .text-bottom{
    padding-top: 30px;
  }
  button{
    border-style: none;
    background-color: $color-dark-blue;
    color: $color-gray-background;
    margin-bottom: 3px;
    &:hover {
      cursor: pointer;
    }
  }
  .divider{
    margin: auto;
    width: 50%;
    word-break: break-all;

    p {
      margin-top: 5px;
      margin-bottom: 5px
    }
  }
  .divider-mobile{
    margin: auto;
    padding-top: 20px;
    width: 100%;
    word-break: break-all;
  }
}


@media screen and (max-width: 600px){
  .bottom{
    flex-direction: column;
    .d{
      display: none;
    }
  }
}
