@import url("https://fonts.googleapis.com/css?family=Abril+Fatface");
@import "../../shared";

.page-recruitment {
  .flex{
    display: flex;
    justify-content: space-evenly;
  }
  .w100{
    width: 100%;
  }
  .header-top {
    text-align: center;
    font-weight: 100;
    padding: 100px 0;
    margin: 0;
  }
  .header {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 0;
    margin: 0;
  }
  .background-gray {
    background-color: $color-gray-background;
  }
  .paragraph {
    text-align: center;
    padding: 5px 12px;
    p {
      font-size: 1.2rem;
    }
  }
  .container {
    padding: 20px 0;
    width: 100%;
  }
  .middle-container{
    padding: 50px 0;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
  }
  a {
    color: $color-light-brown;
    text-decoration: none;
  }
  .spacer {
    height: 100px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      font-size: 1rem;
      font-weight: 200;
      letter-spacing: 0.03rem;
      line-height: 1.7rem;
    }
  }
  li {
    padding: 10px 0;
  }
}

@media screen and (max-width: 800px) {
  .header-top {
    font-size: 2.5rem;
  }
  .flex{
    flex-direction: column;
  }
}
