@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface);
.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif; }


.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.navbar .header {
  height: 80px;
  padding: 0; }
  .navbar .header .logo {
    position: relative;
    height: 80px; }

.navbar nav {
  width: 100%;
  top: 0;
  padding: 0; }

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #01213d; }

.navbar li {
  float: right;
  margin-top: 15px; }
  .navbar li a {
    display: inline-block;
    color: #f2f3f2;
    text-align: center;
    padding: 18px;
    font-size: 0.8rem;
    font-weight: 600;
    text-decoration: none;
    /* no underline */
    letter-spacing: 1px; }
    .navbar li a:hover {
      background-color: #1d68ac; }

div.header {
  padding: 30px; }
  div.header a {
    text-decoration: none;
    /* no underline */
    color: #f2f3f2;
    font-weight: 500; }

.navbar-mobile {
  position: relative;
  height: 90%;
  width: 100%;
  right: 0px;
  top: 0px;
  background: #01213d;
  padding: 0;
  margin: 0;
  text-align: center; }
  .navbar-mobile .logo {
    position: relative;
    height: 80px; }
  .navbar-mobile ul {
    margin: 0;
    margin-top: 20px;
    padding: 0; }
  .navbar-mobile li {
    list-style-type: none; }
    .navbar-mobile li a {
      display: block;
      color: #f2f3f2;
      text-align: center;
      margin: 30px;
      font-weight: 400;
      text-decoration: none;
      /* no underline */
      letter-spacing: 1px;
      font-size: 0.8rem; }
  .navbar-mobile .menu {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .navbar-mobile .menu ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      height: 0;
      opacity: 0; }
  .navbar-mobile div.header {
    padding: 12px 30px;
    padding-right: 8px; }
    .navbar-mobile div.header a {
      text-decoration: none;
      /* no underline */
      color: #f2f3f2; }
  .navbar-mobile .arrow-down {
    padding: 8px 20px;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }
  .navbar-mobile .arrow-up {
    padding: 8px 20px;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  .navbar-mobile button {
    background-color: transparent;
    border: none;
    text-align: center;
    outline: none; }
    .navbar-mobile button:hover {
      cursor: pointer; }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.row {
  display: table;
  margin: auto; }

.col {
  display: table-cell;
  padding-right: 20px;
  padding-left: 20px; }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.intro {
  position: relative;
  display: block;
  background-color: #01213d;
  text-align: center;
  overflow: hidden;
  padding-bottom: 0px;
  width: 100%; }
  .intro h1 {
    color: #5a9bd5;
    padding-top: 10%;
    margin-bottom: 0;
    padding-left: 12px;
    padding-right: 12px; }
  .intro p {
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 0.15rem;
    margin-bottom: 0;
    padding-left: 12px;
    padding-right: 12px; }
  .intro img.image-intro {
    width: 100%;
    max-height: 100%;
    margin-top: -2%;
    margin-bottom: -2%; }

@media only screen and (max-width: 769px) {
  .intro h1 {
    color: #5a9bd5;
    padding-top: 10%;
    font-size: 2.5;
    font-weight: 400; }
  .intro img.image-intro {
    width: 100%;
    max-height: 100%;
    margin-top: 20px;
    margin-bottom: 20px; } }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.row-section {
  text-align: center;
  margin: 0 auto;
  min-width: 60%;
  padding-top: 100px; }
  .row-section .card {
    padding: 0; }
  .row-section .row-text {
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px; }
  .row-section .row-section-image-div {
    width: 60%;
    max-width: 1100px;
    overflow: hidden;
    margin: auto; }

.line {
  width: 60%;
  border-top: 1px solid #7f7251;
  margin-top: 20px; }

.row-section-mobile {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding-top: 50px; }
  .row-section-mobile .card {
    margin: 0 auto;
    width: 90%;
    padding-top: 10px;
    padding-bottom: 10px; }
    .row-section-mobile .card p {
      word-wrap: break-word;
      overflow-wrap: break-word; }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.products {
  padding: 0px; }
  .products .container {
    width: 100%; }
    .products .container .product-card {
      width: 70%;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 50px;
      max-width: 1100px; }
      .products .container .product-card .text {
        position: relative;
        float: left;
        padding-bottom: 50px;
        min-width: 80%; }
      .products .container .product-card .image {
        width: 20%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0; }
        .products .container .product-card .image img {
          height: 350px;
          max-width: 350px; }

.products-mobile {
  padding: 0px; }
  .products-mobile .product-card {
    width: 100%;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px; }
    .products-mobile .product-card .text {
      position: relative;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 50px;
      width: 90%; }
      .products-mobile .product-card .text h3 {
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        word-wrap: break-word;
        overflow-wrap: break-word; }
    .products-mobile .product-card .image {
      position: relative;
      text-align: center;
      height: 100%; }
      .products-mobile .product-card .image img {
        vertical-align: middle;
        width: 300px; }

@media only screen and (min-width: 1201px) {
  .products-mobile {
    display: none; } }

@media only screen and (max-width: 1200px) {
  .products {
    display: none; } }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.contact {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px; }
  .contact .textDiv {
    padding-top: 100px;
    padding-bottom: 100px; }
    .contact .textDiv p {
      font-size: 1.5rem;
      padding-left: 20px; }
    .contact .textDiv h1 {
      font-size: 2rem;
      -webkit-transform: scale(1, 1.1);
              transform: scale(1, 1.1); }
    .contact .textDiv .icon {
      position: relative;
      top: 3px;
      right: 15px;
      height: 23px; }
  .contact a {
    text-decoration: none;
    color: black;
    word-break: break-all; }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.page-contact-info {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  padding-bottom: 50px;
  padding-top: 100px;
  width: 100%; }
  .page-contact-info .header {
    padding: 20px 0; }
  .page-contact-info .person-info {
    padding-bottom: 8px; }
  .page-contact-info .container {
    padding: 40px 0; }
  .page-contact-info .background-gray {
    background-color: #f2f3f2; }
  .page-contact-info .employer-info {
    width: 100%; }
  .page-contact-info h1 {
    font-weight: 100;
    font-size: 3rem;
    padding-top: 0;
    margin: 0; }
  .page-contact-info h5 {
    font-size: 1.3rem;
    margin-bottom: 6px; }
  .page-contact-info p {
    margin-bottom: 6px;
    margin-top: 6px; }
  .page-contact-info a {
    color: black;
    text-decoration: none; }
    .page-contact-info a:hover {
      cursor: pointer;
      opacity: 0.8; }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.page-languages .header {
  padding: 0; }
  .page-languages .header h1 {
    text-align: center;
    font-weight: 100;
    font-size: 3rem;
    padding: 100px 0;
    margin: 0; }
  .page-languages .header p {
    text-align: center; }

.page-languages .table-container {
  padding-bottom: 100px; }
  .page-languages .table-container table {
    width: 99%;
    border-collapse: collapse; }
    .page-languages .table-container table td {
      width: 50%;
      text-align: left;
      padding: 8px;
      border-style: solid;
      border-width: 1px;
      border-color: #c2c2c2; }
    .page-languages .table-container table th {
      text-align: left;
      padding: 8px; }
    .page-languages .table-container table tr:nth-child(even) {
      background-color: #f2f3f2; }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.page-interpreters .header-top {
  text-align: center;
  font-weight: 100;
  font-size: 3rem;
  padding: 100px 0;
  margin: 0; }

.page-interpreters .header {
  text-align: center;
  padding: 20px 0;
  margin: 0; }

.page-interpreters ol {
  list-style: none;
  counter-reset: counter;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 30px 20px; }

.page-interpreters ol li {
  counter-increment: counter;
  display: flex;
  width: calc(50% - 8px);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  padding-right: 8px;
  align-items: center; }

.page-interpreters ol li::before {
  content: counter(counter);
  font-weight: bold;
  font-size: 1.8rem;
  margin-right: 0.5rem;
  line-height: 1;
  width: 34px;
  flex-shrink: 0;
  text-align: center; }

.page-interpreters .people {
  text-align: center;
  padding-bottom: 20px; }

.page-interpreters .background-gray {
  background-color: #f2f3f2; }

.page-interpreters .paragraph {
  text-align: center;
  padding-bottom: 20px; }
  .page-interpreters .paragraph p {
    font-size: 1.2rem; }

.page-interpreters .container {
  padding: 50px 0; }

.page-interpreters a {
  color: #aca658;
  text-decoration: none; }

@media screen and (max-width: 800px) {
  .page-interpreters ol {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto; }
    .page-interpreters ol li {
      width: 100%; } }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.page-recruitment .flex {
  display: flex;
  justify-content: space-evenly; }

.page-recruitment .w100 {
  width: 100%; }

.page-recruitment .header-top {
  text-align: center;
  font-weight: 100;
  padding: 100px 0;
  margin: 0; }

.page-recruitment .header {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0;
  margin: 0; }

.page-recruitment .background-gray {
  background-color: #f2f3f2; }

.page-recruitment .paragraph {
  text-align: center;
  padding: 5px 12px; }
  .page-recruitment .paragraph p {
    font-size: 1.2rem; }

.page-recruitment .container {
  padding: 20px 0;
  width: 100%; }

.page-recruitment .middle-container {
  padding: 50px 0;
  text-align: center;
  max-width: 500px;
  margin: 0 auto; }

.page-recruitment a {
  color: #aca658;
  text-decoration: none; }

.page-recruitment .spacer {
  height: 100px; }

.page-recruitment ul {
  list-style: none;
  padding: 0; }
  .page-recruitment ul li {
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 0.03rem;
    line-height: 1.7rem; }

.page-recruitment li {
  padding: 10px 0; }

@media screen and (max-width: 800px) {
  .header-top {
    font-size: 2.5rem; }
  .flex {
    flex-direction: column; } }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

footer {
  width: 100%;
  text-align: center;
  background-color: #01213d;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-bottom: 30px; }
  footer h2 {
    color: #5a9bd5;
    font-size: 1.3rem; }
  footer h4 {
    line-height: 2px;
    color: white;
    font-size: 1.2rem; }
  footer p {
    color: white;
    font-size: 0.9rem;
    line-height: 20px;
    font-weight: 400;
    margin: 4px; }
  footer a {
    text-decoration: none;
    color: #f2f3f2; }
  footer .logo {
    height: 100px; }
  footer .text-bottom {
    padding-top: 30px; }
  footer button {
    border-style: none;
    background-color: #01213d;
    color: #f2f3f2;
    margin-bottom: 3px; }
    footer button:hover {
      cursor: pointer; }
  footer .divider {
    margin: auto;
    width: 50%;
    word-break: break-all; }
    footer .divider p {
      margin-top: 5px;
      margin-bottom: 5px; }
  footer .divider-mobile {
    margin: auto;
    padding-top: 20px;
    width: 100%;
    word-break: break-all; }

@media screen and (max-width: 600px) {
  .bottom {
    flex-direction: column; }
    .bottom .d {
      display: none; } }

.limited-width {
  max-width: 1100px;
  margin: auto; }

.hr-long {
  border-top: 1px solid #7f7251;
  width: 60%; }

.bg-grey {
  background-color: #f2f3f2; }

.text-center {
  vertical-align: middle; }

.text-middle-center {
  vertical-align: middle;
  text-align: center; }

.width-100 {
  width: 100%; }

.text-align-right {
  text-align: right; }

.text-align-left {
  text-align: left; }

h1 {
  font-size: 3rem;
  font-weight: 500; }

h2 {
  font-size: 2.5rem;
  font-weight: 500; }

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0; }

h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0; }

h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0; }

p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem; }

.modal-bg {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; }
  .modal-bg .modal {
    position: relative;
    border-radius: 8px;
    padding: 40px;
    z-index: 999;
    width: 80%;
    height: 700px;
    max-height: 90vh;
    background-color: #f2f3f2;
    overflow-y: auto; }
    .modal-bg .modal p {
      padding: 10px; }
  .modal-bg button {
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-color: #2c2c2c;
    border-width: thin;
    border-radius: 1px;
    background-color: #5a9bd5;
    font-size: 1rem; }
    .modal-bg button:hover {
      cursor: pointer; }

