@import "../../../shared";

.intro {
	position: relative;
	display: block;
	background-color: $color-dark-blue;
	text-align: center;
	overflow: hidden;
	padding-bottom: 0px;
	width: 100%;
	h1 {
		color: $color-light-blue;
		padding-top: 10%;
		margin-bottom: 0;
		padding-left: 12px;
		padding-right: 12px;
	}
	p {
		color: white;
		font-size: 1.2rem;
		font-weight: 300;
		letter-spacing: 0.15rem;
		margin-bottom: 0;
		padding-left: 12px;
		padding-right: 12px;
	}
	img.image-intro {
		width: 100%;
		max-height: 100%;
		margin-top: -2%;
		margin-bottom: -2%;
  	}
}

@media only screen and (max-width: 769px) {
	.intro {
		h1 {
			color: $color-light-blue;
			padding-top: 10%;
			font-size: 2.5;
			font-weight: 400;
		}
		img.image-intro {
			width: 100%;
			max-height: 100%;
			margin-top: 20px;
			margin-bottom: 20px;
		  }
	}
 }

