@import "../../shared";

.modal-bg {
   background-color: rgba($color: #000000, $alpha: 0.6);
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 999;
   .modal {
      position: relative;
      border-radius: 8px;
      padding: 40px;
      z-index: 999;
      width: 80%;
      height: 700px;
      max-height: 90vh;
      background-color: $color-gray-background;
      overflow-y: auto;
      p {
         padding: 10px;
      }
   }
   button {
      padding: 10px;
      margin: 10px;
      border-style: solid;
      border-color: rgb(44, 44, 44);
      border-width: thin;
      border-radius: 1px;
      background-color: $color-light-blue;
      font-size: 1rem;

      &:hover {
         cursor: pointer;
      }
   }
}
