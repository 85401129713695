$color-light-blue: #5a9bd5;
$color-blue: #1d68ac;
$color-dark-blue: #01213d;
$color-brown: rgb(127, 114, 81);
$color-light-brown: rgb(172, 166, 88);
$color-gray-background: #f2f3f2;

.limited-width {
  max-width: 1100px;
  margin: auto;
}

.hr-long {
  border-top: 1px solid $color-brown;
  width: 60%;
}

.bg-grey {
  background-color: $color-gray-background;
}

.text-center {
  vertical-align: middle;
}

.text-middle-center {
  vertical-align: middle;
  text-align: center;
}

.width-100 {
  width: 100%;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

h1 {
  font-size: 3rem;
  font-weight: 500;
}
h2 {
  font-size: 2.5rem;
  font-weight: 500;
}
h3 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 24px 0;
}
h4 {
  font-size: 2.0rem;
  font-weight: 400;
  margin: 24px 0;
}
h5 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 24px 0;
}
p {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
  line-height: 1.7rem;
}
