@import '../../shared';

.row {
    display: table;
    margin: auto;
  }
  
  .col {
    display: table-cell;
    padding-right: 20px;
    padding-left: 20px;
  }