@import "../../shared";

.page-languages {
  .header {
    padding: 0;
    h1 {
      text-align: center;
      font-weight: 100;
      font-size: 3rem;
      padding:100px 0;
      margin:0;
    }
    p {
      text-align: center;
    }
  }
  .table-container {
    padding-bottom: 100px;

    table {
      width: 99%;
      border-collapse: collapse;
      td {
        width:50%;
        text-align: left;
        padding: 8px;
        border-style: solid;
        border-width: 1px;
        border-color: #c2c2c2;
      }
      th {
        text-align: left;
        padding: 8px;

      }
      tr:nth-child(even) {
        background-color: $color-gray-background;
      }
    }
  }
}
